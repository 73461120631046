<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-03 17:51:23
 * @LastEditTime: 2021-09-26 16:59:06
 * @Description: 济宁中联运行监控大屏
 * @Param:
 * @FilePath: \JT_Web_test\src\views\enterpriseScreen_JN\index_new.vue
 -->


<template>
    <div
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        class="enterpriseScreen_JN"
    >
        <div class="largeScreen_content">
            <header>
                <div class="filterCondition">
                    <el-date-picker
                        type="date"
                        v-model="time"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        @change="selectTime"
                    >
                    </el-date-picker>
                    <el-select
                        v-model="stationId"
                        placeholder="请选择"
                        @change="selectStation"
                    >
                        <el-option
                            v-for="item in stationData"
                            :key="item.station_id"
                            :label="item.station_name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <span>济宁中联运行监控大屏</span>
            </header>
            <section>
                <div class="comtemt_l">
                    <div class="nav">
                        生产发货情况
                    </div>
                    <div class="region_box">
                        <productionDelivery ref="ref_productionDelivery"></productionDelivery>
                    </div>
                </div>
                <div class="comtemt_c">
                    <div class="comtemt_map">
                        <div class="region_box">
                            <logisticsStationMap ref="ref_logisticsStationMap"></logisticsStationMap>
                        </div>
                    </div>
                    <div class="comtemt_monitor">
                        <div class="region_box">
                            <monitor ref="ref_monitor"></monitor>
                        </div>
                    </div>
                </div>
                <div class="comtemt_r">
                    <div class="nav">
                        物资供应情况
                    </div>
                    <div class="region_box">
                        <materialSupply ref="ref_materialSupply"></materialSupply>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import './CSS/index.styl';
import largeScreenFun from './JS/index.js';
import util from '@/utils/util';
import productionDelivery from './productionDelivery.vue';
import logisticsStationMap from './logisticsStationMap.vue';
import monitor from './monitor.vue';
import materialSupply from './materialSupply.vue';

export default {
    name: 'enterprise-screen-new',
    components: {
        productionDelivery,
        logisticsStationMap,
        monitor,
        materialSupply,
    },
    props: {},
    data() {
        return {
            timer: {},
            // 时间
            time: '',
            // 场站
            stationData: [],
            stationId: '',
        };
    },
    computed: {},
    watch: {},
    async created() {
        this.time = util.currentTimeFormat('yyyy-MM-dd');
        this.stationData = await largeScreenFun.getStationData();
        this.stationId = this.stationData[0].station_id;
        this.$refs.ref_logisticsStationMap.initPage();
        this.initData();
    },
    mounted() {
        this.timer = setInterval(this.refreshData, 30000);
    },
    destroyed() {},
    methods: {
        async initData() {
            this.$refs.ref_productionDelivery.initPage();
            this.$refs.ref_monitor.initPage();
            this.$refs.ref_materialSupply.initPage();
        },
        async refreshData() {
            this.$refs.ref_productionDelivery.loadPage();
            this.$refs.ref_monitor.initPage();
            this.$refs.ref_materialSupply.loadPage();
        },
        // 选择日期
        async selectTime() {
            this.$refs.ref_logisticsStationMap.initPage();
            this.initData();
        },
        // 选择场站
        async selectStation() {
            this.$refs.ref_logisticsStationMap.initPage();
            this.initData();
        },
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
<style lang="stylus" scoped></style>
