<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-07 09:55:52
 * @LastEditTime: 2021-09-17 18:17:45
 * @Description: 物资供应情况
 * @Param:
 * @FilePath: \JT_Web_test\src\views\enterpriseScreen_JN\materialSupply.vue
 -->


<template>
    <div class="materialSupply">
        <ul class="taskStatistics">
            <li>
                <h4>供应单(个)：</h4>
                <p class="scrollNumbers scrollNumbers1" id="supplyOrder"></p>
            </li>
            <li>
                <h4>运输车次(次)：</h4>
                <p class="scrollNumbers scrollNumbers2" id="trainNumber"></p>
            </li>
            <li>
                <h4>采购量(吨)：</h4>
                <p class="scrollNumbers scrollNumbers3" id="purchaseQuantity"></p>
            </li>
        </ul>
        <div class="rawMaterial">
            <div>
                <el-table
                    border
                    ref="ref_supplyTable"
                    :data="supplyTableData"
                    class="tableStyle"
                    height="100%"
                    highlight-current-row
                    @current-change="handleCurrentChange"
                >
                    <el-table-column
                        prop="type_name"
                        label="原材料类型"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="supplied_count"
                        label="采购量(吨)"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="count"
                        label="进场车次(次)"
                    >
                    </el-table-column>
                </el-table>
            </div>
            <div id="rawMaterialCharts" class="chartsStyle"></div>
        </div>
        <h4 class="title">
            供应物流车次
        </h4>
        <div class="logisticsTrainnumber">
            <el-input placeholder="请输入内容" v-model="searchVal" class="inputStyle">
                <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
            <el-table
                border
                :data="supplyLogisticsStatistics"
                class="tableStyle"
                height="100%"
                ref="ref_logisticsTrainnumber"
            >
                <el-table-column
                    prop="plate_number"
                    label="车牌号"
                    width="92"
                >
                </el-table-column>
                <el-table-column
                    prop="goods_name"
                    label="材料"
                    width="75"
                >
                </el-table-column>
                <el-table-column
                    prop="source_name"
                    label="货源地"
                >
                </el-table-column>
                <el-table-column
                    prop="station_name"
                    label="供应场站"
                    width="78"
                >
                </el-table-column>
                <el-table-column
                    prop="gross_weight"
                    label="毛重"
                    width="60"
                >
                </el-table-column>
                <el-table-column
                    prop="tare_weight"
                    label="皮重"
                    width="60"
                >
                </el-table-column>
                <el-table-column
                    prop="net_weight"
                    label="净重"
                    width="60"
                >
                </el-table-column>
                <el-table-column
                    prop="gross_time"
                    label="进场时间"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="scope.row.gross_time"
                            placement="top"
                        >
                            <span>{{ scope.row.gross_time.substr(11) }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    width="50"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.transport_status == 0 && scope.row.balance_number == '' ">运输中</span>
                        <span v-else-if="scope.row.status == 0">进场</span>
                        <span v-else-if="scope.row.status == 1">完成</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-block">
                <span class="total">共{{ total }}条</span>
                <el-pagination
                    small
                    background
                    layout="sizes,prev, pager, next, jumper"
                    :current-page="tablePage"
                    :total="total"
                    :page-sizes="[20, 50, 100, 300]"
                    :page-size="pageSize"
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import largeScreenFun from './JS/index.js';

export default {
    name: 'material-supply',
    components: {},
    directives: {
        scroll: {
            inserted: function (el, binding) {
                const cb = binding.value;
                const selectWrap = el.querySelector('.el-table__body-wrapper');
                selectWrap.addEventListener('mousewheel', function (e) {
                    const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
                    const direction = e.deltaY > 0 ? 'down' : 'up';
                    cb(selectWrap, direction, scrollDistance);
                });
            },
        },
    },
    props: {},
    data() {
        return {
            time: '',
            stationId: '',
            // 物资供应情况列表数据
            supplyTableData: [],
            // 物资供应情况列表选中
            currentRow: {},
            // 供应物流车次
            searchVal: '',
            // 供应物流车次列表
            supplyLogisticsStatistics: [],
            // 列表分页下标
            tablePage: 0,
            // 列表总条数
            pageSize: 20,
            total: 0,
            // 是否是最后一页
            isLastPage: false,
            loading: false,

            supplied_count: 0,
            nocount: 0,
            count: 0,
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {},
    methods: {
        async initPage() {
            this.time = this.$parent.time;
            this.stationId = this.$parent.stationId;

            this.searchVal = '';
            this.tablePage = 0;
            this.loadPage();
        },
        async loadPage() {
            const supplyStatistics = await largeScreenFun.getSupplyStatistics(this.time, this.stationId);
            if (supplyStatistics) {
                this.loadsupplyStatistics(supplyStatistics);
            }

            this.supplyTableData = await largeScreenFun.getSupplyStatistics_rawMaterial(this.time, this.stationId);
            if (this.supplyTableData?.length > 0) {
                this.$refs.ref_supplyTable.setCurrentRow(this.supplyTableData[0]);
                this.getSupplyChartData(this.supplyTableData[0]);
            }
            this.getPageData();
        },
        search() {
            this.tablePage = 0;
            this.getPageData();
        },
        // 加载物资供应情况统计数据
        loadsupplyStatistics(data) {
            if (this.supplied_count !== data.supplied_count) {
                this.supplied_count = data.supplied_count;
                largeScreenFun.electronicNumber('#purchaseQuantity', this.supplied_count || 0);
            }
            if (this.nocount !== data.nocount) {
                this.nocount = data.nocount;
                largeScreenFun.electronicNumber('#trainNumber', this.nocount || 0);
            }
            if (this.count !== data.count) {
                this.count = data.count;
                largeScreenFun.electronicNumber('#supplyOrder', this.count || 0);
            }
        },
        // 物质供应情况列表选择
        handleCurrentChange(row) {
            this.getSupplyChartData(row);
        },

        async getSupplyChartData(data) {
            const row = await largeScreenFun.getSupplyStatistics_chart(this.time, data.type_code);
            const chartData = {
                xAxis: {
                    data: [],
                },
                yAxis: {
                    name: '(吨)',
                    nameTextStyle: [0, 0, -7, 0],
                },
                series: [
                    {
                        name: '计划采购量',
                        barWidth: 24,
                        color: [ '#76E0A8', '#13BD63'],
                        data: [],
                    }, {
                        name: '实际采购量',
                        barWidth: 24,
                        color: ['#EFAB54', '#FA8C47' ],
                        data: [],
                    },
                ],
            };
            row.forEach(item => {
                chartData.xAxis.data.push(item.station_name);
                chartData.series[0].data.push(item.supply_count);
                chartData.series[1].data.push(item.supplied_count);
            });
            this.columnDiagram('rawMaterialCharts', chartData);
        },

        // 获取分页数据
        async getPageData() {
            this.loading = true;
            const res = await largeScreenFun.getSupplyLogisticsStatistics(this.time, '', this.searchVal, this.tablePage, this.pageSize);
            this.total = Number(res.count);
            this.supplyLogisticsStatistics = res.List;
            this.loading = false;
        },
        // 更改供应物流车次页码大小
        pageSizeChange(size) {
            this.pageSize = size;
            this.getPageData();
        },
        // 更改供应物流车次页数
        pageCurrentChange(page) {
            this.tablePage = page;
            this.getPageData();
        },
        // 鼠标滚动加载更多
        async scrollFn(obj, direction, scrollTop) {
            if (direction === 'up'
                || this.loading === true
                || this.isLastPage === true
            ) {return;}
            if (scrollTop <= 150) {
                if (this.supplyLogisticsStatistics.length < this.total) {
                    this.tablePage++;
                    this.loading = true;
                    const res = await largeScreenFun.getSupplyLogisticsStatistics(this.time, '', this.searchVal, this.tablePage);
                    this.total = res.count;
                    this.supplyLogisticsStatistics.push(...res.List || []);
                    this.$nextTick(() => {
                        this.$refs.ref_logisticsTrainnumber.doLayout();
                    });
                    this.loading = false;
                } else {
                    this.isLastPage = true;
                }
            }
        },

        // 柱图
        columnDiagram(obj, data) {
            const chart = this.$echarts.init(document.getElementById(obj));
            const option = {
                tooltip: {},
                grid: {
                    top: 30,
                    left: 40,
                    bottom: 30,
                    right: 0,
                },
                xAxis: {
                    data: data.xAxis.data,
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.5)',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        nameTextStyle: {
                            color: '#fff',
                            fontSize: 12,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [

                ],
            };
            data.series.forEach((item, index) => {
                let newSymbolOffset = [];
                if (index === 0) {
                    newSymbolOffset = [-16, 10];
                } else {
                    newSymbolOffset = [16, 10];
                }
                option.series.push({
                    name: item.name,
                    type: 'bar',
                    barWidth: item.barWidth,
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: item.color[0],
                            },
                            {
                                offset: 1,
                                color: item.color[1],
                            },
                            ]),
                        },
                    },
                    data: item.data,
                    z: 10,
                    zlevel: 0,
                    label: {
                        show: false,
                        position: 'top',
                        distance: 10,
                        fontSize: 16,
                        color: '#01fff4',
                    },
                },
                {
                    // 分隔
                    type: 'pictorialBar',
                    itemStyle: {
                        normal: {
                            color: '#0F375F',
                        },
                    },
                    symbolRepeat: 'fixed',
                    symbolMargin: 3,
                    symbol: 'rect',
                    symbolClip: true,
                    symbolSize: [24, 2],
                    symbolOffset: newSymbolOffset,
                    data: item.data,
                    zlevel: 1,
                }
                );
            });
            chart.setOption(option);
        },
    },
};
</script>

<style scoped lang="stylus">
</style>
