<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-17 16:35:01
 * @LastEditTime: 2021-10-13 09:44:27
 * @Description:
 * @Param:
 * @FilePath: \JT_Web_test\src\views\enterpriseScreen_JN\monitor.vue
 -->
<template>
    <div class="monitor">
        <div class="navBar">
            <template v-for="(item, index) in navData">
                <div
                    :key="`nav_${index}`"
                    :class="{'active': index == navIndex}"
                    @click="selectNav(item, index)"
                >
                    <h5>{{ item.name }}</h5>
                    <ul>
                        <template v-for="(k, j) in item.childNavData">
                            <li
                                :key="`childNav_${j}`"
                                :class="{'current': j == childNavIndex}"
                                @click.stop="selectChildNav(k, j)"
                            >
                                {{ k.name }}
                            </li>
                        </template>
                    </ul>
                </div>
            </template>
        </div>
        <div class="monitorBox" v-if="navIndex === 0">
            <ul class="aggregate siloStyle" v-if="childNavType ==='aggregate'">
                <li>
                    <template v-for="(item, index) in coarseAggregate">
                        <div :key="`coarseAggregate_${index}`">
                            <h3>{{ item.stock_no }}</h3>
                            <div class="siloDiagram coarseAggregate_siloDiagram">
                                <h4>{{ item.volume_theory }}</h4>
                                <span>{{ item.volume_warn }}</span>
                                <el-progress :percentage="item.percentage" :show-text="false" :color="item.color"></el-progress>
                            </div>
                            <p><span class="tag" :style="{background:item.color}"></span>{{ item.stock_name }}</p>
                        </div>
                    </template>
                </li>
                <li>
                    <template v-for="(item, index) in fineAggregate">
                        <div :key="`fineAggregate_${index}`">
                            <h3>{{ item.stock_no }}</h3>
                            <div class="siloDiagram fineAggregate_siloDiagram">
                                <h4>{{ item.volume_theory }}</h4>
                                <span>{{ item.volume_warn }}</span>
                                <el-progress :percentage="item.percentage" :show-text="false" :color="item.color"></el-progress>
                            </div>
                            <p><span class="tag" :style="{background:item.color}"></span>{{ item.stock_name }}</p>
                        </div>
                    </template>
                </li>
            </ul>
            <el-carousel
                arrow="always"
                class="powderLiquid"
                :autoplay="false"
                indicator-position="none"
                v-if="childNavType ==='powderLiquid'"
            >
                <template v-for="(item, index) in powderLiquidListData">
                    <el-carousel-item :key="`grouping_${index}`">
                        <template v-for="(k, j) in item">
                            <div class="productionLine" :key="`line_${j}`">
                                <h2>{{ k[0].team_name }}</h2>
                                <ul class="siloStyle">
                                    <li class="powderSilo">
                                        <template v-for="(z, c) in k">
                                            <div :key="`powder_${c}`" v-if="['207', '208'].indexOf(z.type_dic_code) < 0">
                                                <h3>{{ z.stock_no }}</h3>
                                                <div class="siloDiagram coarseAggregate_powder">
                                                    <h4>{{ z.volume_theory }}</h4>
                                                    <span>{{ z.volume_warn }}</span>
                                                    <el-progress :percentage="z.percentage" :show-text="false" :color="z.color"></el-progress>
                                                </div>
                                                <p><span class="tag" :style="{background:z.color}"></span>{{ z.stock_name }}</p>
                                            </div>
                                        </template>
                                    </li>
                                    <li class="liquidMaterialSilo">
                                        <template v-for="(z, c) in k">
                                            <div :key="`liquidMaterial_${c}`" v-if="['207', '208'].indexOf(z.type_dic_code) > -1">
                                                <h3>{{ z.stock_no }}</h3>
                                                <div class="siloDiagram coarseAggregate_liquidMaterialSilo">
                                                    <h4>{{ z.volume_theory }}</h4>
                                                    <span>{{ z.volume_warn }}</span>
                                                    <strong :style="{height: calculatedHeight(z.percentage)}"></strong>
                                                </div>
                                                <p><span class="tag" :style="{background:z.color}"></span>{{ z.stock_name }}</p>
                                            </div>
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </el-carousel-item>
                </template>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import largeScreenFun from './JS/index.js';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);

export default {
    name: 'monitor',
    components: {},
    props: {},
    data() {
        return {
            stationId: '',
            navData: [{
                name: '库存监控',
                val: 'nventoryMonitoring',
                childNavData: [{
                    name: '骨料',
                    val: 'aggregate',
                }, {
                    name: '粉料&液料',
                    val: 'powderLiquid',
                }],
            },
            // {
            //     name: '视频监控',
            //     childNavData: [{
            //         name: '厂区监控',
            //     }, {
            //         name: '砂石分离监控',
            //     }, {
            //         name: '粉料厂监控',
            //     }],
            // },
            ],
            navIndex: 0,
            childNavIndex: 0,
            childNavType: 'aggregate',
            // 骨料
            aggregate: [],
            // 粗骨料
            coarseAggregate: [],
            // 细骨料
            fineAggregate: [],
            // 粉料/液料
            powderLiquid: [],
            powderLiquidListData: [],
        };
    },
    watch: {
        aggregate(newVal) {
            if (newVal.length > 0) {
                this.coarseAggregate = newVal.filter(item => item.type_dic_code === '204');
                this.fineAggregate = newVal.filter(item => item.type_dic_code === '203');
            }
        },
        powderLiquid(newVal) {
            if (newVal.length > 0) {
                const row = [];
                const result = newVal.reduce((a, b) => {
                    if (a[b.team_id]) {
                        a[b.team_id].push(b);
                    } else {
                        a[b.team_id] = [b];
                    }
                    return a;
                }, {});

                for (const k in result) {
                    row.push(result[k]);
                }
                const grouping = Math.ceil(row.length / 2);
                for (let i = 0; i < grouping; i++) {
                    if (i === 0) {
                        this.powderLiquidListData.push([...row.slice(i, i + 2)]);
                    } else if (i === grouping - 1) {
                        this.powderLiquidListData.push([...row.slice(i * 2, row.length)]);
                    } else {
                        this.powderLiquidListData.push([...row.slice(i * 2, i * 2 + 2)]);
                    }
                }
            }
        },
    },
    computed: {},
    created() {},
    mounted() {
        this.initData();
    },
    methods: {
        async initPage() {
            this.stationId = this.$parent.stationId;

            const siloData = await largeScreenFun.getSiloData(this.stationId);
            if (siloData?.length > 0) {
                this.loadSiloData(siloData);
            }
        },

        async initData() {
            const tenantId = this.$takeTokenParameters('TenantId');
            const videoGrouping = await largeScreenFun.generateNumber(this.stationId, tenantId);
            if (videoGrouping?.length > 0) {
                this.loadvideoGroupingData(videoGrouping);
            }
        },

        // 处理料仓数据
        loadSiloData(data) {
            data.forEach(item => {
                let new_volume_theory = null;
                let percentage = null;
                if (item.volume_theory < 0) {
                    new_volume_theory = 0;
                } else {
                    new_volume_theory = item.volume_theory;
                }
                if (new_volume_theory === 0) {
                    percentage = 0;
                } else if (Number(item.volume_max) > 0) {
                    percentage = NP.times(NP.divide(new_volume_theory, item.volume_max).toFixed(2), 100);
                } else {
                    percentage = 100;
                }
                if (percentage > 100) {
                    percentage = 100;
                }
                item.percentage = percentage;
                if (item.volume_theory <= item.volume_warn) {
                    item.color = '#FF5353';
                } else if (item.volume_theory > item.volume_warn && item.volume_theory < item.volume_max / 2) {
                    item.color = '#F7B500';
                } else {
                    item.color = '#6DD400';
                }
            });
            this.aggregate = data.filter(item => ['203', '204'].indexOf(item.type_dic_code) > -1);
            this.powderLiquid = data.filter(item => ['203', '204'].indexOf(item.type_dic_code) === -1);
        },

        // 处理视频分组数据
        loadvideoGroupingData() {
            // debugger;
        },

        calculatedHeight(nub) {
            let new_height = null;
            if (nub > 71) {
                new_height = 71;
            } else {
                new_height = nub;
            }
            return new_height + '%';
        },

        selectNav(item, index) {
            this.navIndex = index;
            this.childNavIndex = 0;
            if (item.val === 'nventoryMonitoring') {
                this.childNavType = 'aggregate';
            } else {
                this.childNavType = '';
            }
        },

        selectChildNav(item, index) {
            this.childNavType = item.val;
            this.childNavIndex = index;
        },
    },
};
</script>

<style scoped lang="stylus"></style>
